import { httpGet } from "../httpClient";

export async function getOfferOriginsAndDestinationsByUserLocation(userOriginCode, userCountryCode) {
    try {
        // Step 1: Get the closest airport code
        const closestAirportResponse = await httpGet(`/api/geo/closest?countryCode=${userCountryCode}${userOriginCode ? `&regionCode=${userOriginCode}` : ''}`);
        if (!closestAirportResponse.ok) {
            throw new Error(`Failed to fetch closest airport code: ${closestAirportResponse.statusText}`);
        }
        const closestAirportText = await closestAirportResponse.text();
        const closestAirport = closestAirportText ? JSON.parse(closestAirportText) : null;

        // Step 2: Get origins by user country
        const originsResponse = await httpGet(`/api/flight/v2/origin?country=${userCountryCode}`);
        if (!originsResponse.ok) {
            throw new Error(`Failed to fetch origins: ${originsResponse.statusText}`);
        }
        const origins = await originsResponse.json();
        // Set first origin as most popular
        origins[0].isMostPopular = true;
        const mostPopularOrigin = origins[0];
        origins?.sort((a, b) => a.name.localeCompare(b.name));

        // Step 3: Get destinations by closest airport code
        // TDOD: Handle case when closest airport code is not found
        const destinationsResponse = await httpGet(`/api/flight/v2/destination?country=${userCountryCode}${closestAirport?.code ? `&origin=${closestAirport.code}` : `&origin=${mostPopularOrigin.code}`}`);
        if (!destinationsResponse.ok) {
            throw new Error(`Failed to fetch destinations: ${destinationsResponse.statusText}`);
        }
        const destinations = await destinationsResponse.json();
        destinations?.sort((a, b) => a.name.localeCompare(b.name));

        // Step 4: Return both lists
        return { origins, destinations, closestAirport };
    } catch (error) {
        console.error("Error fetching origins and destinations:", error);
        throw error;
    }
}

export async function getOfferDestinationsByOrigin(originCode, userCountryCode) {
    try {
        const destinationsResponse = await httpGet(`/api/flight/v2/destination?country=${userCountryCode}${originCode ? `&origin=${originCode}` : ''}`);
        if (!destinationsResponse.ok) {
            throw new Error(`Failed to fetch destinations: ${destinationsResponse.statusText}`);
        }
        const destinations = await destinationsResponse.json();
        destinations?.sort((a, b) => a.name.localeCompare(b.name));
        return destinations;
    } catch (error) {
        console.error("Error fetching destinations:", error);
        throw error;
    }
}

export async function isCountryHasOffers(userCountryCode) {
    const originsResponse = await httpGet(`/api/flight/v2/origin?country=${userCountryCode}`);
    if (!originsResponse.ok) {
        throw new Error(`Failed to fetch origins: ${originsResponse.statusText}`);
    }
    const origins = await originsResponse.json();

    return Boolean(origins?.length);
}