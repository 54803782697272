import { useSelector } from 'react-redux';
import { COUNTRY_CURRENCY_MAP } from '../../config/countryToCurrencyMap';
import { useEffect, useState } from 'react';


export const isUserCurrencyCountryValid = () => {
  const userStore = useSelector((state) => state.user.value);
  const { country, currency } = userStore;

  // State to store the validation result
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const validateCurrencyCountry = () => {
      if (!country || !currency) {
        setIsValid(true);
        return;
      }

      const expectedCurrency = COUNTRY_CURRENCY_MAP[country];
      setIsValid(currency === expectedCurrency);
    };

    // Perform validation whenever country or currency changes
    validateCurrencyCountry();
  }, [country, currency]); // Dependency array ensures useEffect runs on changes

  return isValid;
};