export const COUNTRY_CURRENCY_MAP = {
    AR: 'ARS', // Argentina
    AT: 'EUR', // Austria
    AU: 'AUD', // Australia
    BE: 'EUR', // Belgium
    BO: 'BOB', // Bolivia
    BR: 'BRL', // Brazil
    CA: 'CAD', // Canada
    CL: 'CLP', // Chile
    CO: 'COP', // Colombia
    CR: 'CRC', // Costa Rica
    CZ: 'CZK', // Czechia
    DK: 'DKK', // Denmark
    DO: 'DOP', // Dominican Republic
    EC: 'USD', // Ecuador
    SV: 'USD', // El Salvador
    FI: 'EUR', // Finland
    FR: 'EUR', // France
    DE: 'EUR', // Germany
    GR: 'EUR', // Greece
    GT: 'GTQ', // Guatemala
    HK: 'HKD', // Hong Kong
    HN: 'HNL', // Honduras
    ID: 'IDR', // Indonesia
    IE: 'EUR', // Ireland
    IL: 'ILS', // Israel
    IN: 'INR', // India
    IT: 'EUR', // Italia
    JP: 'JPY', // Japan
    MX: 'MXN', // Mexico
    MY: 'MYR', // Malaysia
    NI: 'NIO', // Nicaragua
    NL: 'EUR', // Netherlands
    NO: 'NOK', // Norway
    NZ: 'NZD', // New Zealand
    PA: 'USD', // Panama
    PE: 'PEN', // Peru
    PH: 'PHP', // Philippines
    PL: 'PLN', // Poland
    PR: 'USD', // Puerto Rico
    PT: 'EUR', // Portugal
    PY: 'PYG', // Paraguay
    QA: 'QAR', // Qatar
    RO: 'RON', // Romania
    SA: 'SAR', // Saudi Arabia
    SG: 'SGD', // Singapore
    ZA: 'ZAR', // South Africa
    KR: 'KRW', // South Korea
    ES: 'EUR', // Spain
    SE: 'SEK', // Sweden
    CH: 'CHF', // Switzerland
    TH: 'THB', // Thailand
    TR: 'TRY', // Turkey
    TW: 'TWD', // Taiwan
    UA: 'UAH', // Ukraine
    AE: 'AED', // United Arab Emirates
    GB: 'GBP', // United Kingdom
    US: 'USD', // United States
    UY: 'UYU', // Uruguay
    VN: 'VND', // Vietnam
  };